import request from '@/utils/request'

//诊断记录一览
export function getDiagnosticTools(data) {
  return request({
    url: "/questionnaire/getDiagnosticTools",
    method: "post",
    data
  })
}

//根据诊断id导出excel表格
export function exportExcel(data) {
  return request({
    url: "/questionnaire/export",
    method: "post",
    responseType: "blob",
    data
  })
}

//诊断详情
export function selectDiagnose(query) {
  return request({
    url: '/questionnaire/selectDiagnoseTwo',
    method: 'get',
    params: query
  })
}
// export function selectDiagnose(query) {
//   return request({
//     url: '/questionnaire/selectDiagnose',
//     method: 'get',
//     params: query
//   })
// }
//根据id查询信息
export function selectById(query) {
  return request({
    url: '/webUser/selectById',
    method: 'get',
    params: query
  })
}
//发布报告
export function updateRelease(query) {
  return request({
    url: '/questionnaire/updateRelease',
    method: 'post',
    params: query
  })
}
//获取申报政策
export function selectPolicyDeclaration(data) {
  return request({
    url: "/questionnaire/selectPolicyDeclarationTwo",
    method: "post",
    data
  })
}
//获取分析报告
export function selectReport(data) {
  return request({
    url: "/questionnaire/selectReport",
    method: "post",
    data
  })
}
//历史诊断记录
export function selectDiagnosticLogout(data) {
  return request({
    url: "/questionnaire/selectDiagnosticLogout",
    method: "post",
    data
  })
}
//修改诊断书建议
export function updateDiagnose(data) {
  return request({
    url: "/questionnaire/updateDiagnose",
    method: "post",
    data
  })
}
//手动修改申报政策
export function updateolicyState(data) {
  return request({
    url: "/questionnaire/updateDeclarationPolicyState",
    method: "post",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdf(data) {
  return request({
    url: "/questionnaire/downloadPdf",
    method: "post",
    responseType: "blob",
    data
  })
}
//下载诊断政策doc文档
export function downloadPdfReport(data) {
  return request({
    url: "/questionnaire/downloadPdfReport",
    method: "post",
    // responseType: "blob",
    data
  })
}
//预约记录
export function diagnosticAppointment(data) {
  return request({
    url: "/questionnaire/diagnosticAppointment",
    method: "post",
    data
  })
}
//预约排期
export function updateIsSchedule(query) {
  return request({
    url: "/questionnaire/updateIsSchedule",
    method: "get",
    params: query
  })
}
