import request from "@/utils/request"

// 图片、文件上传
export function uploadAdmin(data) {
  return request({
    url: "/uploadPublic/upload",
    method: "post",
    data
  })
}

// 单文件上传
export function apiUpload(data) {
  return request({
    url: '/public/apiUpload',
    method: 'POST',
    data
  })
}

//codeType查询表
export function getCode(data) {
  return request({
    url: '/code/getCode',
    method: 'get',
    params: data
  })
}

// 查询城市
export function getCityAll(data) {
  return request({
    url: '/city/getCityAll',
    method: 'get',
    params: data
  })
}
