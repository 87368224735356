export const pagination = {
  data() {
    return {
      total: null
    }
  },
  methods: {
    // 条数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize

      this.search()
    },

    // 分页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage

      this.search()
    }
  }
}
